import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';
import { useCookies } from 'react-cookie';

const DashBoardProtectedRoutes = () => {
  const [cookies, setCookie] = useCookies(['user']);
  const location = useLocation();

  if (!cookies?.user?.token) {
    // Save location to state or local storage before redirect
    const from = location.pathname + location.search;

    sessionStorage.setItem('lepole_previousRoute', from); // Replace with desired storage method

    return <Navigate to="/login" replace />;
  }

  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};

export default DashBoardProtectedRoutes;
